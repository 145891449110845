import React from 'react'
import './AppointmentBookingForm.css'

const AppointmentBookingForm = ({slot, doctor, bookAppointment, hospitalId, patientDetails, handleInputChange,setShowForm}) => {
    
  return (
    <div className='booking-form-container'>
    <button onClick={() => setShowForm(false)}>X</button>
    <form
                          onSubmit={(event) => {
                            event.preventDefault();
                            bookAppointment(doctor, hospitalId, slot);
                          }}
                        >
                          <div className="patient-booking">
                            <input
                              className="patient-name-input-box"
                              type="text"
                              name="name"
                              placeholder="Enter patient name"
                              value={patientDetails.name}
                              onChange={handleInputChange}
                            />
                            <input
                              className="patient-age-input-box"
                              type="number"
                              name="age"
                              placeholder="Enter patient age"
                              value={patientDetails.age}
                              onChange={handleInputChange}
                            />
                            <br/>
                            <label className="select-gender-text">
                              Select gender:
                            </label>
                            <select
                              className="select-gender-box"
                              name="gender"
                              value={patientDetails.gender}
                              onChange={handleInputChange}
                            >
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Others">Others</option>
                            </select>
                          </div>
                          <button
                            className="book-appointment-buttn"
                            type="submit"
                          >
                            Book Appointment
                          </button>
                        </form>
                        </div>
  )
}

export default AppointmentBookingForm