import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UpdateSlotTimings.css';

function UpdateDoctorTimings({ doctorId, handleToggleForm }) {
  const [slots, setSlots] = useState([]);
  const [editingSlotId, setEditingSlotId] = useState(null);
  const [slotData, setSlotData] = useState({ name: '', startTime: '', endTime: '', active: false });

  useEffect(() => {
    const fetchSlots = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/slots/doctors/${doctorId}`);
        setSlots(response.data);
        //console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSlots();
  }, [doctorId]);

  const handleEdit = (slot) => {
    setEditingSlotId(slot._id);
    setSlotData(slot);
  };

  const handleSave = async (slotId) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/slots/${slotId}`, slotData);
      setEditingSlotId(null);
      setSlotData({ name: '', startTime: '', endTime: '', active: false });
      handleToggleForm(doctorId);

    } catch (error) {
 
      console.error(error);
   
    }
  };


  



const handleToggleActive = async (slotId) => {
  try {
    const slot = slots.find(slot => slot._id === slotId);
    const updatedSlot = { ...slot, active: !slot.active };
    await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/slots/${slotId}`, updatedSlot);
    setSlots(slots.map(slot => slot._id === slotId ? updatedSlot : slot));
  } catch (error) {
    console.error(error);
  }
};



const handleDelete = async (slotId) => {
  const confirmDelete = window.confirm('Are you sure you want to delete this slot?');
  if (confirmDelete) {
    try {
      //console.log(slotId);
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/slots/${slotId}`);
      setSlots(slots.filter(slot => slot._id !== slotId));
    } catch (error) {
      console.error(error);
    }
  }
};



return (
  <div className='slot-list'>
    {slots.length === 0 ? (
      <p>No slots set yet .Add new slots.</p>
    ) : (
      slots.map((slot) => (
        <div key={slot._id} className='each-slot'>
          {editingSlotId === slot._id ? (
            <div className='edit-form'>
              <input placeholder='e.g. Slot 1' type="text" id='slotname' name='slotname' value={slotData.name} onChange={(e) => setSlotData({ ...slotData, name: e.target.value })} />
              <input placeholder='e.g. 9 AM' type="text" id='slotstime' name='slotstime' value={slotData.startTime} onChange={(e) => setSlotData({ ...slotData, startTime: e.target.value })} />
              <input placeholder='e.g. 12 PM' id='slotetime' name='slotetime' type="text" value={slotData.endTime} onChange={(e) => setSlotData({ ...slotData, endTime: e.target.value })} />
              <button onClick={() => handleSave(slot._id)}>Save</button>
            </div>
          ) : (
            <>
              <h3>{slot.name}</h3>
              <p>Start Time: {slot.startTime}</p>
              <p>End Time: {slot.endTime}</p>
              <span className={`switch-label-2 ${slot.active ? 'Yes' : 'No'}`}>
        Active: {slot.active ? 'Yes' : 'No'}
      </span>
              <label className={`switch-2 ${slot.active ? "active-2" : ""}`}>
                <input type="checkbox" id='yes' name='yes' checked={slot.active} onChange={() => handleToggleActive(slot._id)} />
                <span className="slider-2 round-2"></span>
              </label>
              <div className='edit-del-btns'>
              <button onClick={() => handleEdit(slot)}>Edit</button>
              <button onClick={() => handleDelete(slot._id)}>Delete</button>
              </div>
            </>
          )}

        </div>
      ))
    )}
  </div>
);
}

export default UpdateDoctorTimings;