// components/UserLogin.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './UserLogin.css';
import { Link } from 'react-router-dom';

function UserLogin({onLogin}) {
  const [mobileNo, setMobileNo] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = (token) => {
    // Store the token in local storage so it can be used for authenticated
    localStorage.setItem('authToken', token);

    // Navigate to the home page or dashboard
    navigate('/usershomepage');
  }

  
  const onSubmit = async e => {
    e.preventDefault();
    const user = {
      mobileNo: '91' + mobileNo,
      password
    };

    try {
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/userlogin`, user);
    
      // The server responds with a JWT. Store it in local storage.
      localStorage.setItem('token', res.data.token);
    //console.log(res.data);


    // Now the user is logged in. You might want to redirect them to their profile page or something.
    handleLogin(res.data.token);


          // Now the user is logged in. You might want to redirect them to their profile page or something.
        }  catch (error) {
          if(error.response && error.response.status === 401) {
            console.error(error);
            alert('mobile number not registered');
            setErrorMessage('mobile number not registered');
          } else if (error.response && error.response.status === 402){
            alert('wrong password ');
            setErrorMessage('wrong password ');
          }
          else {
            alert('undefined error');
          }
        }
      };

      const handleForgotPasswordClick = () => {
        navigate('/ufp');
      };
    

  return (
    <div className='login-container'>
      <div className="health-eagle-ls">
        <h1 className="health-eagle-text">Q24 HEALTH</h1>
        <div className='first-step'>
        READY TO DIVE IN? LET'S GET STARTED!!!
        </div>
      </div>

      <div className="login-form">
    <form className="form-background" onSubmit={onSubmit}>
    <div className="form-titlel">
      <h1>LOGIN</h1>
    <input className="form-input1" type="text" placeholder="Mobile Number" onChange={e => setMobileNo(e.target.value)} />
    <input className="form-input1" type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
    <div className="forgot-password"> 
     <p onClick={handleForgotPasswordClick} className="forgot-password-link">Forgot Password?</p>
    </div>
    {errorMessage && <div className="error-message">{errorMessage}
    </div>}
      
    <button className="login-button" type="submit">Login</button>
      <Link to="/usersignup" className="signup-link">New user? Register here</Link>
      </div>
    </form>
    </div>


    </div>
  );
}

export default UserLogin;
