import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './RegistrationRequests.css';

function RegistrationRequests() {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/allRR`);
    setRequests(response.data);
  };

  const approveRequest = async (id) => {
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/approveRequest`, { id });
    fetchRequests();
  };

  const rejectRequest = async (id) => {
    await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/rejectRequest/${id}`);
    fetchRequests();
  };

  return (
    <div className='main-req-box'>
      <p>Total Registration Requests: {requests.length}</p>
    {requests.length === 0 ? (
      <p>No registration requests</p>
    ) : (
      requests.map(request => (
        <div className='request-box' key={request._id}>
          <p>Name: {request.name}</p>
          <p>Address: {request.address}</p>
          <p>Pincode: {request.pincode}</p>
          <p>Doctors:</p>
          {request.doctors.map((doctor, index) => (
            <div key={index}>
              <p>{doctor.name}{doctor.speciality}</p>
            </div>
          ))}
          <p>Contact No: {request.contactNo}</p>
          <p>Contact Email: {request.contactEmail}</p>
          <button onClick={() => approveRequest(request._id)}>Approve</button>
          <button onClick={() => rejectRequest(request._id)}>Reject</button>
        </div>
      ))
    )}
  </div>
);
}

export default RegistrationRequests;