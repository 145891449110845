import React from 'react';
import './TermsOfService.css';

const TermsOfService = () => {
  return (
    <div className="terms-container">
      <h1>Terms of Service</h1>
      <p>Welcome to Q24 Health App. These terms and conditions outline the rules and regulations for the use of our application.</p>
      
      <h2>1. Introduction</h2>
      <p>By accessing this app, we assume you accept these terms and conditions. Do not continue to use Q24 Health App if you do not agree to all of the terms and conditions stated on this page.</p>
      
      <h2>2. License</h2>
      <p>Unless otherwise stated, Q24 Health and/or its licensors own the intellectual property rights for all material on Q24 Health App. All intellectual property rights are reserved. You may access this from Q24 Health App for your own personal use subjected to restrictions set in these terms and conditions.</p>
      
      <h2>3. User Responsibilities</h2>
      <p>Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account.</p>
      
      <h2>4. Limitation of Liability</h2>
      <p>In no event shall Q24 Health, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this app.</p>
      
      <h2>5. Changes to Terms</h2>
      <p>We reserve the right to amend these terms at any time. It is your responsibility to review these terms periodically.</p>
      
      <h2>Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us at contactq24@gmail.com.</p>
    </div>
  );
};

export default TermsOfService;