import React, { useState } from 'react';
import './UserHeader.css'
import { NavLink , useLocation } from 'react-router-dom'
import UserProfilePage from './UserProfilePage';


const UserHeader = () => {
    const [showProfile, setShowProfile] = useState(false);

    const handleProfilepageRedirect = () => {
        setShowProfile(!showProfile);
      };

      const location = useLocation();
  return (
    <>
    <div className='userheader'>
        <div className='userheader-container'>
        <a href="/" style={{ textDecoration: 'none', color: 'inherit' }}>
  <h1>Q24 HEALTH</h1>
</a>
        <div className="user-nav-menu">
            <ul className='user-nav-menu-list'>
            <NavLink to='/usershomepage' style={{color: location.pathname === '/usershomepage' ? '#14d77c' : '#000000'} }><li>HOME</li></NavLink>
            <NavLink to='/hospitallistforusers' style={{color: location.pathname === '/hospitallistforusers' ? '#14d77c' : '#000000'} }><li>Search Hospitals</li></NavLink>
            <NavLink to="/activeappointments" style={{color: location.pathname === '/activeappointments' ? '#14d77c' : '#000000'} }><li>My Appointments</li></NavLink>
            </ul>
        </div>
        <div className="userheader-buttons">
        <button className="Profile-button" onClick={handleProfilepageRedirect}>
      <i className="fas fa-user"></i>
    </button>        </div>
        </div>
    </div>
    <div className="userheader-mobilecontainer">

    <NavLink to='/hospitallistforusers'>
  <div style={{
    backgroundColor: location.pathname === '/hospitallistforusers' ? '#14d77c' : '#ffffff',
    color: location.pathname === '/hospitallistforusers' ? '#ffffff' : '#000000'
  }} className="userheader-mobile-bookappointments">
    BOOK APPOINTMENTS
  </div>
</NavLink>
<NavLink to='/usershomepage'>
  <div style={{
    backgroundColor: location.pathname === '/usershomepage' ? '#14d77c' : '#ffffff',
    color: location.pathname === '/usershomepage' ? '#ffffff' : '#000000',
    border: location.pathname === '/usershomepage' ? '1px solid #ffffff' : '1px solid  #14d77c'
  }} className="userheader-mobile-home">
    HOME
  </div>
</NavLink>
<NavLink to="/activeappointments">
  <div style={{
    backgroundColor: location.pathname === '/activeappointments' ? '#14d77c' : '#ffffff',
    color: location.pathname === '/activeappointments' ? '#ffffff' : '#000000'
  }} className="userheader-mobile-activeappointments">
    ACTIVE APPOINTMENTS
  </div>
</NavLink>
    </div>
    {showProfile &&
 <UserProfilePage/>
}

    </>
  )
}

export default UserHeader