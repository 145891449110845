import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import UpdateDoctorTimings from './UpdateSlotTimings';
import './HospitalDetails.css';
import AddDoctorSlot from './AddDoctorSlot';
import HospitalHeader from './HospitalHeader';

function HospitalDetails() {
  const [hospitalDetails, setHospitalDetails] = useState(null);
  const [doctorsAvailability, setDoctorsAvailability] = useState({});
  const [expandedDoctor, setExpandedDoctor] = useState(null);
  const [expandedAddSlotDoctor, setExpandedAddSlotDoctor] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hpassword, setPassword] = useState(''); // Add this line
  const [showChangePassword, setShowChangePassword] = useState(false); // Add this line
  const navigate = useNavigate();

  const fetchHospitalDetails = useCallback( async () => {
    try {
      const hospitaldetailstoken = localStorage.getItem('hospitaltoken');
      if (!hospitaldetailstoken) {
        throw new Error('No token found');
      }
  
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/auth/hospitalprofile`, {
        headers: { Authorization: `Bearer ${hospitaldetailstoken}` }
      });
  
      if (response.status !== 200) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
  
      setHospitalDetails(response.data);
  
      // Initialize doctorsAvailability state with the actual availability of the doctors
      const availability = {};
      response.data.doctors.forEach(doctor => {
        availability[doctor._id] = doctor.availability === 'Available';
      });
      setDoctorsAvailability(availability);
    } catch (error) {
      console.error('Failed to fetch hospital details:', error);
      setErrorMessage('Couldnt fetch hospital details. Please try again later.');
      localStorage.removeItem('hospitaltoken');
      navigate('/receptionlogin');
    }
    
  },[navigate]);

  useEffect(() => {
    fetchHospitalDetails().catch(error => {
      console.error('Error in useEffect:', error);
    });
  }, [fetchHospitalDetails]);


  const handleToggleForm = (doctorId) => {
    setExpandedDoctor(expandedDoctor === doctorId ? null : doctorId);
    setExpandedAddSlotDoctor(null); // Close the Add Slot form
  };

  const handleToggleAddSlotForm = (doctorId) => {
    setExpandedAddSlotDoctor(expandedAddSlotDoctor === doctorId ? null : doctorId);
    setExpandedDoctor(null); // Close the Slot Settings form
  };







const logout = () => {
  if (window.confirm('Are you sure you want to logout?')) {
    localStorage.removeItem('hospitaltoken');
    navigate('/');
  }
};
 


// handling the toggle of availability
  const handleToggle = async (doctorId) => {
    try {
      const currentAvailability = doctorsAvailability[doctorId];
      const newAvailability = !currentAvailability ? 'Available' : 'Not Available';
  
      const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/doctors/${doctorId}/availability`, {
        availability: newAvailability
      });
  
      if (response.status !== 200) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
  
      // Update the doctor's availability in the state
      setDoctorsAvailability({
        ...doctorsAvailability,
        [doctorId]: !currentAvailability
      });
    } catch (error) {
      console.error('Failed to update doctor availability:', error);
    }
  };







if (!hospitalDetails) {
    return <div className="spinner-container">
    <div className="spinner">
    </div>
    Loading....
  </div>;
  }




  const handleSubmit = async (e) => {
    e.preventDefault();

    const confirmation = window.confirm("Are you sure you want to change the password?");
    if (!confirmation) {
        return; // If the user clicks "Cancel", stop here
    }

    const hospital = { hospitalNumber: hospitalDetails.hospitalNumber, hpassword };

    try {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/hospitalsignup`, hospital);
        alert('Login password changed successfully');
        setShowChangePassword(false);
        localStorage.removeItem('token');
        navigate('/receptionlogin');
    } catch (error) {
        alert('Error registering hospital');
    }
};

  const handleChangePasswordClick = () => {
    setShowChangePassword(prevShowChangePassword => !prevShowChangePassword);
  };



  return (
    <>
    <HospitalHeader/>
    <div className='m-div'>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <div className='navig'>
      
<div className='logout-button-2'>
      <button onClick={logout}>Logout</button>
</div>
<div className='change-password'>
        <button onClick={handleChangePasswordClick}>Change Password</button>
        {showChangePassword && (
          <form onSubmit={handleSubmit}>
            <input type="password" placeholder="Password" value={hpassword} onChange={(e) => setPassword(e.target.value)} required />
            <button type="submit">Confirm</button>
          </form>
        )}
      </div>
</div>

<div className="hos-details-banner">
  
    {hospitalDetails.name} (
      {`${hospitalDetails.address} `}
      {hospitalDetails.pincode} )
      <p>Hospital Number: {hospitalDetails.hospitalNumber}</p>
      </div>
    </div>

      <h2>Doctors Settings</h2>

    <div className="di-2"> 

{hospitalDetails.doctors.map((doctor, index) => (
  <div key={index} className="each-d">
    <div className='dname-togg'>
    <div className='dname-dspec'>
      <div className='dname-dspec-col'>
      <h3 className="doctor-name">{doctor.name}</h3>
<p className="doctor-speciality">{doctor.speciality}</p>
    </div>
    <div>
    <label className="switch">
      <input type="checkbox" checked={doctorsAvailability[doctor._id] || false} onChange={() => handleToggle(doctor._id)} />
      <span className="slider round"></span>
    </label>
    </div>
    
    </div>
    <div className='two-btn'>
  <div>
    <button
      className={expandedDoctor === doctor._id ? 'active' : ''}
      onClick={() => handleToggleForm(doctor._id)}
    >
      {doctor.name}'s Slot settings
    </button>
  </div>
  <div>
    <button
      className={expandedAddSlotDoctor === doctor._id ? 'active' : ''}
      onClick={() => handleToggleAddSlotForm(doctor._id)}
    >
      Add Slot to {doctor.name}
    </button>
  </div>
</div>
   
    
{expandedDoctor === doctor._id && <UpdateDoctorTimings doctorId={doctor._id} handleToggleForm={handleToggleForm}/>}
    {expandedAddSlotDoctor === doctor._id && <AddDoctorSlot doctorId={doctor._id }  setExpandedDoctor={setExpandedDoctor}
     handleToggleAddSlotForm={handleToggleAddSlotForm} 
    handleToggleForm={handleToggleForm}/>}
    </div>

    
    
  </div>
))}

</div>
    </>
  );
}

export default HospitalDetails;