import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element: Component, tokenName, redirectPath }) => {
  const token = localStorage.getItem(tokenName);

  if (token) {
    return <Navigate to={redirectPath} />;
  }

  return <Component />;
};

export default ProtectedRoute;