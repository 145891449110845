import React, { useState } from 'react';
import axios from 'axios';
import './AddDoctorSlot.css';

function AddDoctorSlot({ doctorId,handleToggleAddSlotForm ,handleToggleForm,setExpandedDoctor}) {
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/slots/doctors/${doctorId}`, { slot: slotData });
      setSlotData({ name: '', startTime: '', endTime: '', active: false });
      
      handleToggleAddSlotForm(doctorId);
      alert('Slot added successfully');
      handleToggleForm(doctorId);
      
    } catch (error) {
      if(error.response && error.response.status === 401) {
        console.error(error);
        alert('Slot with this name already exists');
      } else {
        alert('undefined error');
      }
    }
  };

  const [slotData, setSlotData] = useState({
  name: 'Slot 1',
  startTime: '09:30 AM',
  endTime: '12:30 PM',
  active: true
});
  
return (
  <form className='form1' onSubmit={handleSubmit}>
  <input type="text" id='slotname1' name='slotname1' value={slotData.name} onChange={(e) => setSlotData({ ...slotData, name: e.target.value })} placeholder="Slot Name" required />
  <input type="text" id='slotstime1' name='slotstime1' value={slotData.startTime} onChange={(e) => setSlotData({ ...slotData, startTime: e.target.value })} placeholder="Start Time" required />
  <input type="text" id='slotetime1' name='slotetime1' value={slotData.endTime} onChange={(e) => setSlotData({ ...slotData, endTime: e.target.value })} placeholder="End Time" required />
  <label className="switch">
    <input type="checkbox" id='active' name='active' checked={slotData.active} onChange={(e) => setSlotData({ ...slotData, active: e.target.checked })} />
    <span className="slider"></span>
  </label>
  <span className={`switch-label ${slotData.active ? 'on' : 'off'}`}>
    {slotData.active ? 'On' : 'Off'}
  </span>
  <button type="submit">Add Slot</button>
</form>
);
}

export default AddDoctorSlot;