import React from 'react';
import { Link } from 'react-router-dom';
import './AdminNavbar.css';

function AdminNavbar() {
  return (
    <nav className="navbar">
      <Link to="/registration-form">Add Hospital as admin</Link>
      <Link to="/admin/listOfHospitals">SHOW list of hospitals</Link>
      <Link to="/admin/requests">Approve Requests</Link>
    </nav>
  );
}

export default AdminNavbar;