import React, { useState, useEffect } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import './dashboard.css';
import HospitalHeader from './HospitalHeader';


function Dashboard() {
  const [appointments, setAppointments] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(localStorage.getItem('selectedDoctor') || "");
  const [selectedDoctorObject, setSelectedDoctorObject] = useState(null); // New state variable for the entire doctor object
  const [hospitalDetails, setHospitalDetails] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [tickedAppointments, setTickedAppointments] = useState(JSON.parse(localStorage.getItem('tickedAppointments')) || {});
  const socket = io(`${process.env.REACT_APP_API_BASE_URL}`);
  const [patientName, setPatientName] = useState('');
  const [patientAge, setPatientAge] = useState('');
  const [patientGender, setPatientGender] = useState('');
  const [patientContact, setPatientContact] = useState('');
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(localStorage.getItem('selectedSlot') || '');
  const [selectedSlotObject, setSelectedSlotObject] = useState(null); // New state variable for the entire slot object
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [noAppointmentsMessage, setNoAppointmentsMessage] = useState('');
  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);
  const [PatientAddedSuccessMessage, setPatientAddedSuccessMessage] = useState('');

  useEffect(() => {
    const fetchHospitalDetails = async () => {
      const hospitaldetailstoken = localStorage.getItem('hospitaltoken');
      if (!hospitaldetailstoken) {
        console.error('No token found');
        return;
      }
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/auth/hospitalprofile`, {
          headers: { Authorization: `Bearer ${hospitaldetailstoken}` }
        });
        setHospitalDetails(response.data);
        setDoctors(response.data.doctors);
        if (!selectedDoctor && response.data.doctors.length > 0) {
          const firstDoctor = response.data.doctors[0];
          setSelectedDoctor(firstDoctor.name);
          setSelectedDoctorObject(firstDoctor); // Set the entire doctor object
          setSlots(firstDoctor.slots);
          if (!selectedSlot && firstDoctor.slots.length > 0) {
            setSelectedSlot(firstDoctor.slots[0]._id);
            localStorage.setItem('selectedSlot', firstDoctor.slots[0]._id);
            setSelectedSlotObject(firstDoctor.slots[0]); // Set the entire slot object
          }
        } else {
          const doctor = response.data.doctors.find(doc => doc.name === selectedDoctor);
          if (doctor) {
            setSelectedDoctorObject(doctor); // Set the entire doctor object
            setSlots(doctor.slots);
          }
        }
        //console.log(response.data);
      } catch (error) {
        console.error('Failed to fetch hospital details:', error);
      }
    };
    fetchHospitalDetails();
  }, [selectedDoctor, selectedSlot]);

  useEffect(() => {
    const fetchAppointments = async () => {
      if (!selectedSlot) return;
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/filterAppointmentsByslots`, {
          params: { qslotId: selectedSlot }
        });
        if (response.data.message === 'No appointments yet') {
          setNoAppointmentsMessage(response.data.message);
          setAppointments([]);
        } else {
          setNoAppointmentsMessage('');
          setAppointments(response.data);
        }
        //console.log("appointments sent by backend", response.data);
      } catch (error) {
        console.error('Failed to fetch appointments:', error);
      }
    };
    fetchAppointments();
  }, [selectedSlot]);

  useEffect(() => {
    if (selectedSlot) {
      const slot = slots.find(slot => slot._id === selectedSlot);
      setSelectedSlotObject(slot);
    }
  }, [selectedSlot, slots]);

  const handleDoctorChange = (event) => {
    const selectedDoctorName = event.target.value;
    setSelectedDoctor(selectedDoctorName);
    localStorage.setItem('selectedDoctor', selectedDoctorName);
    const doctor = doctors.find(doc => doc.name === selectedDoctorName);
    if (doctor) {
      setSelectedDoctorObject(doctor); // Set the entire doctor object
      setSlots(doctor.slots);
      if (doctor.slots.length > 0) {
        setSelectedSlot(doctor.slots[0]._id);
        localStorage.setItem('selectedSlot', doctor.slots[0]._id);
        setSelectedSlotObject(doctor.slots[0]);
      } else {
        setSelectedSlot('');
        localStorage.removeItem('selectedSlot');
        setSelectedSlotObject(null);
      }
    }
  };

  const handleSlotChange = (event) => {
    const selectedSlotId = event.target.value;
    setSelectedSlot(selectedSlotId);
    localStorage.setItem('selectedSlot', selectedSlotId);
  };

  const handleCheckboxChange = async (appointment) => {
    const isConfirmed = window.confirm("Are you sure? This action is undoable.");
    if (!isConfirmed) {
      return;
    }
    try {
      const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/appointments/${appointment._id}/complete`, {}, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.status !== 200) {
        throw new Error('Failed to update appointment');
      }
    
      const updatedTickedAppointments = { ...tickedAppointments, [appointment._id]: true };
      setTickedAppointments(updatedTickedAppointments);
      localStorage.setItem('tickedAppointments', JSON.stringify(updatedTickedAppointments));
      const dataToSend = {
        currentQueueNumber: appointment.queueNumber,
        slotId: selectedSlot
      };
      //console.log('Sending data:', dataToSend);
      socket.emit('queue update', dataToSend);
    } catch (error) {
      console.error('Error updating appointment:', error);
      alert('Failed to update appointment. Please try again.');
    }
  };

  const handleAddPatient = async (e) => {
    e.preventDefault();
    try {
      const appointmentData = {
        slot: selectedSlotObject,
        doctorId: selectedDoctorObject._id,
        hospitalId: hospitalDetails,
        patient: {
          name: patientName,
          age: patientAge,
          gender: patientGender,
          contact: `91${patientContact}`,
        },
        user: {
          fullName: patientName,
          mobileNo: `91${patientContact}`,
        },
      };
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/BookAppointment`, appointmentData);
      setPatientName('');
      setPatientAge('');
      setPatientGender('');
      setPatientContact('');
      setPatientAddedSuccessMessage('Patient was added successfully!');
      setIsModalVisible(false);
      setTimeout(() => {
        window.location.reload(); // Refresh the page after 2 seconds
      }, 2000);
    } catch (error) {
      console.error('Error adding patient:', error);
    }
  };


  if (!hospitalDetails) {
    return <div className="spinner-container">
    <div className="spinner">
    </div>
    Loading....
  </div>;
  }


return (
    <>
    <HospitalHeader/>
    <div className="dashboard">
    <div className="add-patient-section">
      
      {isModalVisible && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={hideModal}>&times;</span>
            {/* Form goes here */}
            <form onSubmit={handleAddPatient} className="add-patient-form">
              <input
                type="text"
                value={patientName}
                onChange={(e) => setPatientName(e.target.value)}
                placeholder="Patient Name"
                required
              />
              <input
                type="number"
                value={patientAge}
                onChange={(e) => setPatientAge(e.target.value)}
                placeholder="Patient Age"
                required
              />
              <select
                value={patientGender}
                onChange={(e) => setPatientGender(e.target.value)}
                required
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
              <input
                type="text"
                value={patientContact}
                onChange={(e) => setPatientContact(e.target.value)}
                placeholder="Patient Contact"
                required
              />
              <button type="submit">Add Patient</button>
            </form>
          </div>
        </div>
      )}
    </div>
    
    <div className="success-message-section">
      {PatientAddedSuccessMessage && <div className="success-message">{PatientAddedSuccessMessage}</div>}
    </div>
   <div className='selection-section'>
    <div className="doctor-selection-section">
      <label htmlFor="doctor-select">Select Doctor:</label>
      <select id="doctor-select" value={selectedDoctor} onChange={handleDoctorChange}>
        <option value="">--Please choose a doctor--</option>
        {doctors.map((doctor) => (
          <option key={doctor._id} value={doctor.name}>
            {doctor.name} - {doctor.speciality}
          </option>
        ))}
      </select>
    </div>
    
    {selectedDoctor && (
      <div className="slot-selection-section">
        <label htmlFor="slot-select">Select Slot:</label>
        <select id="slot-select" value={selectedSlot} onChange={handleSlotChange}>
          <option value="">--Please choose a slot--</option>
          {slots.map((slot) => (
            <option key={slot._id} value={slot._id}>
              {slot.name} ({slot.startTime} - {slot.endTime})
            </option>
          ))}
        </select>
      </div>
      
    )}
    </div>
    
    <div className="appointments-section">
    <h2>Appointments</h2>
    <div className='refresh-btn'>
            <button onClick={() => window.location.reload()}>Refresh</button>
            </div>
      {noAppointmentsMessage ? (
        <p>{noAppointmentsMessage}</p>
      ) : (
        appointments.length > 0 && (
          <div className='appointments-inner-section' >
        
            
           
            <ul>
              {appointments.map((appointment) => (
                <div className='each-appointment-sheet'>
                <li key={appointment._id}>
                    <div className='patient-info'>

                    <div className="patient-token">{appointment.queueNumber}</div>
                <div className="patient-name">{appointment.patient.name}</div>
                <div className="patient-age-gender">
                <div className="patient-age">{appointment.patient.age}</div>
                <div className="patient-gender">{appointment.patient.gender}</div>
                </div>
              
                <div className="patient-contact">{appointment.user?.mobileNo ?? 'No Contact'}</div>
               
                    </div>
                    <input
                    type="checkbox"
                    checked={tickedAppointments[appointment._id] || false}
                    onChange={() => handleCheckboxChange(appointment)}
                    disabled={tickedAppointments[appointment._id] || false}
                  />
                </li>
                </div>
              ))}
            </ul>
          </div>
        )
      )}<div className='add-patient-btn'>
          <button  onClick={showModal}>Add Patient</button>
          </div>
    </div>

  </div>
  </>
);
}

export default Dashboard;