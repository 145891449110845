import React, { useState, useEffect } from 'react';

import './HospitalsList.css';
function HospitalsList() {
    const [hospitals, setHospitals] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/GetAllHospitals`)
            .then(response => response.json())
            .then(data => setHospitals(data))
            .catch(error => console.error(error));
    }, []);



    
    return (
        <div>
            <h1>Hospitals List</h1>
            {hospitals.map((hospital, index) => (
        <div key={index} className="hospital-item">
            <button onClick={() => setActiveIndex(activeIndex === index ? null : index)}>
                <h2>{hospital.name}</h2>
                <p>{hospital.address}</p>
                <p>{hospital.pincode}</p>
            </button>
            {activeIndex === index && (
                <div className="doctors-list">
                    <h3>Doctors</h3>
                    {hospital.doctors.map((doctor, index) => (
                        <div key={index}>
                            <p>{doctor.name}</p>
                            <p>{doctor.speciality}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    ))}
        </div>






    );
}

export default HospitalsList;



/*//functional
{hospitals.map((hospital, index) => (
        <div key={index} className="hospital-item">
            <button onClick={() => setActiveIndex(index)}>
                <h2>{hospital.name}</h2>
                <p>{hospital.address}</p>
                <p>{hospital.pincode}</p>
            </button>
            {activeIndex === index && (
                <div className="doctors-list">
                    <h3>Doctors</h3>
                    {hospital.doctors.map((doctor, index) => (
                        <div key={index}>
                            <p>{doctor.name}</p>
                            <p>{doctor.speciality}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    ))}


    */




    /*//non functional

                {hospitals.map((hospital, index) => (
                <div key={index}>
                    <h2>{hospital.name}</h2>
                    <p>{hospital.address}</p>
                    <p>{hospital.pincode}</p>
                    <h3>Doctors</h3>
                    {hospital.doctors.map((doctor, index) => (
                        <div key={index}>
                            <p>{doctor.name}</p>
                            <p>{doctor.speciality}</p>
                        </div>
                    ))}
                </div>
            ))}
        

        */
