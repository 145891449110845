// components/ForgotPassword.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import './ForgotPassword.css';

function ForgotPassword() {
  const [mobileNo, setMobileNo] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
const navigate = useNavigate();
useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://otpless.com/v2/auth.js';
    script.async = true;
    script.id = 'otpless-sdk';
    script.type = 'text/javascript';
    script.dataset.appid = 'O5MEE8MOBQ7VER65BL6Z';
    document.body.appendChild(script);

    window.otpless = (otplessUser) => {
    //   alert(JSON.stringify(otplessUser));
      setMobileNo(otplessUser.identities[0].identityValue);
      setIsAuthenticated(true);
    };
    
    return () => {
        document.body.removeChild(script);
      };
    }, []);


  const onSubmit = async e => {
    e.preventDefault();
    const user = {
      mobileNo,
      newPassword
    };

    try {
        //console.log(user);
      //const res =
       await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/auth/updatepassword`, user);
      //console.log(res.data);
      alert('Password updated successfully');
        navigate('/userlogin');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.msg);
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
      }
    }
  };

  return (
    <div className='forgot-password-container'>
      <div className="forgot-password-form">
        <form className='form-background' onSubmit={onSubmit}>
          <div className="form-title">
            <h1>Forgot Password</h1>
            {isAuthenticated ? (
              <>
                <input className="form-input" type="password" placeholder="New Password" onChange={e => setNewPassword(e.target.value)} /><br />
                <button className="update-button" type="submit">Update Password</button>
                <p>{errorMessage}</p>
              </>
            ) : (
              <>
                <script id="otpless-sdk" type="text/javascript" src="https://otpless.com/v2/auth.js" data-appid="O5MEE8MOBQ7VER65BL6Z"></script>
                <div id="otpless-login-page"></div>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;