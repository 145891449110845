import React, { useState } from 'react';
import axios from 'axios';

function HospitalSignup({ hospitalNumber }) { // Add a hospitalNumber prop
  const [hpassword, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const hospital = { hospitalNumber, hpassword };

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/hospitalsignup`, hospital);
      alert('login password changed successfully');
    } catch (error) {
      alert('Error registering hospital');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="password" placeholder="Password" value={hpassword} onChange={(e) => setPassword(e.target.value)} required />
      <button type="submit">confirm</button>
    </form>
  );
}

export default HospitalSignup;