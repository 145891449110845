// patients/UserSignup.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './UserSignup.css';

function UserSignup() {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://otpless.com/v2/auth.js';
    script.async = true;
    script.id = 'otpless-sdk';
    script.type = 'text/javascript';
    script.dataset.appid = 'O5MEE8MOBQ7VER65BL6Z';
    document.body.appendChild(script);

    window.otpless = (otplessUser) => {
      // alert(JSON.stringify(otplessUser));
      
      setMobileNo(otplessUser.identities[0].identityValue);
      setIsAuthenticated(true);
    };
    

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const onSubmit = async e => {
    e.preventDefault();
    const newUser = {
      fullName,
      mobileNo,
      password
    };

    try {
      //const res = 
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/usersignup`, newUser);
      //console.log(res.data);
      alert('User Signed Up Successfully')
      navigate('/userlogin');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.msg);
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
      }
    }
  };

  return (
    <div className='signup-container'>
      <div className="health-eagle-ls">
        <h1 className="health-eagle-text">HEALTH EAGLE</h1>
        <div className="first-step">
          TAKE THE FIRST STEP TOWARDS CONVENIENT HEALTHCARE ACCESS..
        </div>
      </div>

      <div className="signup-form">
        <form className='form-background' onSubmit={onSubmit}>
          <div className="form-title">
            <h1>SIGN UP</h1>
            {isAuthenticated ? (
              <>
                <input className="user-form-input" type="text" placeholder="Full Name" onChange={e => setFullName(e.target.value)} /><br />
                <input className="user-form-input" type="password" placeholder="Create New Password" onChange={e => setPassword(e.target.value)} /><br />
                <button className="register-button" type="submit">REGISTER</button>
                <Link to="/userlogin" className="login-link">Already an user? Login here</Link>
                <p>{errorMessage}</p>
              </>
            ) : (
              <>
                <script id="otpless-sdk" type="text/javascript" src="https://otpless.com/v2/auth.js" data-appid="O5MEE8MOBQ7VER65BL6Z"></script>
                <div id="otpless-login-page"></div>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default UserSignup;