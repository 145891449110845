import React, { useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client';
import axios from 'axios';
import './FirstAppointment.css';

const ENDPOINT = `${process.env.REACT_APP_API_BASE_URL}`;

function FirstAppointment() {
  const [appointments, setAppointments] = useState([]);
  const [currentQueueNumbers, setCurrentQueueNumbers] = useState(
    JSON.parse(localStorage.getItem('currentQueueNumbers')) || {}
  );

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);

    socket.on('connect', () => {
      // console.log('Connected to WebSocket server');
    });

    socket.on('connect_error', (error) => {
      // console.error('Connection error:', error);
    });

    socket.on('disconnect', (reason) => {
      // console.log('Disconnected from WebSocket server:', reason);
    });

    socket.on('reconnect_attempt', () => {
      // console.log('Attempting to reconnect...');
    });

    socket.on('reconnect_failed', () => {
      // console.error('Reconnection failed');
    });



    //to handle queue update

    const queueUpdateHandler = (data) => {
      // console.log('Received queue update:', data);
      setCurrentQueueNumbers(prevNumbers => {
        const updatedNumbers = {
          ...prevNumbers,
          [data.slotId]: data.currentQueueNumber
        };
        // console.log('Updated queue numbers:', updatedNumbers);
        localStorage.setItem('currentQueueNumbers', JSON.stringify(updatedNumbers));

        const firstAppointment = appointments[0];
        if (firstAppointment && firstAppointment.slotId === data.slotId) {
          // console.log('First appointment found:', firstAppointment);
          //console.log('Notification permission status:', Notification.permission);

          if (Notification.permission === 'granted') {
            if (data.currentQueueNumber <= firstAppointment.queueNumber) {
              navigator.serviceWorker.ready.then(registration => {
                registration.showNotification('Queue Update', {
                  body: `Current queue number: ${data.currentQueueNumber}\nYour token number: ${firstAppointment.queueNumber}`
                }).catch(error => console.error('Notification error:', error));
              }).catch(error => console.error('Service worker error:', error));
            }
          } else if (Notification.permission === 'denied') {
            //console.log('Notification permission is blocked. Please enable notifications to receive live queue status of your appointments.');
            alert('Please enable notifications to receive live queue status of your appointments.');
            Notification.requestPermission().then(permission => {
              //console.log('Notification permission result:', permission);
              if (permission === 'granted' && data.currentQueueNumber <= firstAppointment.queueNumber) {
                navigator.serviceWorker.ready.then(registration => {
                  registration.showNotification('Queue Update', {
                    body: `Current queue number: ${data.currentQueueNumber}\nYour token number: ${firstAppointment.queueNumber}`
                  }).catch(error => console.error('Notification error:', error));
                }).catch(error => console.error('Service worker error:', error));
              }
            }).catch(error => console.error('Permission request error:', error));
          } else {
            //console.log('Requesting notification permission...');
            Notification.requestPermission().then(permission => {
              //console.log('Notification permission result:', permission);
              if (permission === 'granted' && data.currentQueueNumber <= firstAppointment.queueNumber) {
                navigator.serviceWorker.ready.then(registration => {
                  registration.showNotification('Queue Update', {
                    body: `Current queue number: ${data.currentQueueNumber}\nYour token number: ${firstAppointment.queueNumber}`
                  }).catch(error => console.error('Notification error:', error));
                }).catch(error => console.error('Service worker error:', error));
              }
            }).catch(error => console.error('Permission request error:', error));
          }
        }
        return updatedNumbers;
      });
    };






    socket.on('queue update', queueUpdateHandler);

    return () => {
      //console.log('Cleaning up WebSocket connection');
      socket.disconnect();
    };
  }, [appointments]); // Add appointments as a dependency

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/auth/userprofile`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const response1 = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/appointments?usermobileNo=${response.data.mobileNo}`);
      setAppointments(response1.data);
    } catch (error) {
      console.error('Failed to fetch user', error);
    }
  };

  const firstAppointment = appointments[0];
  const doctor = firstAppointment?.hospitalId 
    ? firstAppointment.hospitalId.doctors.find(doctor => doctor._id === firstAppointment.doctorId)
    : null;






  return (
    <div className="firstappointment-page-appointment-container">
    {firstAppointment ? (
      <div className="firstappointment-page-appointment-box">
      <div className="firstappointment-page-hospital-name">
        {firstAppointment?.hospitalId ? firstAppointment.hospitalId.name : 'You have no active appointments'}
      </div>
      <div className="firstappointment-page-doctor-name">
        {doctor ? doctor.name : ''}
      </div>
        <div className="firstappointment-page-doctor-name">
        {firstAppointment?.slot?.name}___
        {firstAppointment?.slot?.startTime}_ 
        {firstAppointment?.slot?.endTime}
        
      </div>
      <div className="firstappointment-page-line1"></div>

      <div className="firstappointment-page-patient-name">
        
        {firstAppointment?.patient?.name}
      </div>


      <div className="firstappointment-page-current-token">
        CURRENT TOKEN NUMBER
        <div className="firstappointment-page-token-box2" >
      <div className="firstappointment-page-live-box" >

      <div className="firstappointment-page-live-dot" />
      <div className="firstappointment-page-live-text">LIVE</div>

  
      </div>
      <div className="firstappointment-page-current-queue-number">
        {currentQueueNumbers[firstAppointment?.slotId]}
      </div>
      </div>
      </div>


      <div className="firstappointment-page-line2"></div>
      <div className="firstappointment-page-your-token">
        YOUR TOKEN NUMBER
        <div className="firstappointment-page-token-box1" >
      <div className="firstappointment-page-queue-number">
        {firstAppointment?.queueNumber}
      </div>
      </div>
      </div>



    </div>
  
  ) : (
    <div className='xyz'>You have no active appointments</div>
  )}
</div>
  );
}

export default FirstAppointment;