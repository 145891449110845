import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import './Header.css';

const Header = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/userlogin');
  };

  const handleSignupClick = () => {
    navigate('/usersignup');
  };
  
  return (
    <div className="header">
    <div className="header-container">
    <a href="/" style={{ textDecoration: 'none', color: 'inherit' }}>
  <h3>Q24 HEALTH</h3>
</a>
      <div className="header-nav-menu">
      <ul className='nav-menu-list'>
            <ScrollLink className='li' to="Home" smooth={true} duration={1000}>HOME</ScrollLink> {/* Use the ScrollLink component here */}
            <ScrollLink className='li' to="Hospitals" smooth={true} duration={1000}>HOSPITALS</ScrollLink>
            <li>SERVICES</li>
            <li>ABOUT</li>
          </ul>
      </div>

    <div className="buttons">
        <button className="signup" onClick={handleSignupClick}>SIGNUP</button>
        <button className="login" onClick={handleLoginClick}>LOGIN</button>
        </div>

    </div>
    </div>
  );
};

export default Header;