import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
// import './otplessmodule.js';
import Home from './components/Home';
import UserSignup from './user/UserSignup';
import UserLogin from './user/UserLogin';
import UsersHomepage from './user/UsersHomepage';
import HospitalList from './components/HospitalList';
import ForgotPassword from './user/ForgotPassword.js';
import ProtectedRoute from './components/ProtectedRoute';


import HospitalDetails from './reception/HospitalDetails';
import UserProfile from './user/UserProfilePage';
import ActiveAppointments from './user/ActiveAppointments';
import FirstAppointment from './user/FirstAppointment';
import UpdateDoctorTimings from './reception/UpdateSlotTimings';
import HospitalLogin from './reception/HospitalLogin';
import HospitalForm from './components/HospitalForm';
import UserProfilePage from './user/UserProfilePage';
import HospitalsList from './admin/HospitalsList';
import { AdminWithPasswordPrompt } from './admin/AdminWithPasswordPrompt';
import Dashboard from './reception/dashboard';
import HospitalListForUsers from './user/HospitalListForUsers';
import HospitalSignup from './reception/HospitalSignup';
import RegistrationRequests from './admin/RegistrationRequests';
import TermsOfService from './components/TermsOfService';

const App = () => {
  return (
    <Router>
 
    <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/usersignup" element={<ProtectedRoute element={UserSignup} tokenName="token" redirectPath="/usershomepage" />} />
      <Route path="/userlogin" element={<ProtectedRoute element={UserLogin} tokenName="token" redirectPath="/usershomepage" />} />
      <Route path="/receptionlogin" element={<ProtectedRoute element={HospitalLogin} tokenName="hospitaltoken" redirectPath="/hospital-dashboard" />} />
    <Route path="/usershomepage" element={<UsersHomepage />} />
    <Route path="/hlist" element={<HospitalList />} />
    <Route path="/terms-of-service" element={<TermsOfService />} />



 
<Route path="/admin" element={<AdminWithPasswordPrompt />} />
    <Route path='/load-hospital-details' element={<HospitalDetails/>} />
    <Route path='/doctorsAndAppointments' element={<Dashboard/>} />
   
    <Route path="/userprofile" element={<UserProfile/>}/>
    <Route path="/activeappointments" element={<ActiveAppointments/>}/>
    <Route path="/firstappointment" element={<FirstAppointment />} />
    <Route path="/update-doctor-timings" element={<UpdateDoctorTimings />} />

    <Route path="/registration-form" element={<HospitalForm />} />
    <Route path="/userprofilepage" element={<UserProfilePage/>} />
    <Route path="/hospital-dashboard" element={<Dashboard/>} />
    <Route path="/admin/listOfHospitals" element={<HospitalsList/>} />
    <Route path="/hospitallistforusers"  element={<HospitalListForUsers/>} />
    <Route path="/set" element={<HospitalSignup/>} />
    <Route path="/admin/requests" element={<RegistrationRequests/>} />
    <Route path="/ufp" element={<ForgotPassword/>} />

    </Routes>
  </Router>
    

  

  );
};

export default App;