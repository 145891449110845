import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './UserProfilePage.css';
import { Link, useNavigate } from 'react-router-dom';

function UserProfilePage() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem('token'); // assuming the JWT is stored in local storage
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/auth/userprofile`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setUser(response.data);
      } catch (error) {
        console.error('Failed to fetch user', error);
      }
    };
    fetchUser();
    
    document.body.classList.add('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };

  }, []);

  if (!user) {
    return <div className="spinner-container">
    <div className="spinner">
    </div>
    Loading....
  </div>;
  }

  const handleLogout = () => {
    if (window.confirm('Are you sure you want to logout?')) {
      localStorage.clear(); // clear local storage
      navigate('/'); // redirect to landing page
    }
  };

  const handleProfileClick = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <div className={`profile-blur ${isActive ? 'active' : ''}`} onClick={handleProfileClick}></div>
      <div className={`profile-container ${isActive ? 'active' : ''}`}>
        <div className="profile-header"></div>
        <div className="profile-avatar"> <i className="fas fa-user"></i></div>
        <div className="profile-item username">{user.fullName}</div>
        <div className="profile-item phone-number">{user.mobileNo}</div>
        <div className="profile-item change-password"><Link to='/ufp'>CHANGE PASSWORD</Link></div>
        <div className="profile-item help">HELP</div>
        <div className="profile-item about-us">ABOUT US</div>
        <div className="logout-button" onClick={handleLogout}>LOGOUT</div>
      </div>
    </>
  );
}

export default UserProfilePage;