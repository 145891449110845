import React, { useState } from 'react';
import axios from 'axios'
import './HospitalForm.css';
import { useNavigate } from 'react-router-dom';



const HospitalForm = () => {
  const navigate = useNavigate();

  const [hospital, setHospital] = useState({
    name: '',
    address: '',
    pincode: '',
    hospitalNumber: '',
    contactNo: '',
    contactEmail: '',
    doctors: [{ name: '', speciality: '' }]
  });



  const handleHospitalChange = (e) => {
    setHospital({ ...hospital, [e.target.name]: e.target.value });
  }

  const handleDoctorChange = (e, index) => {
    const doctors = [...hospital.doctors];
    doctors[index][e.target.name] = e.target.value;
    setHospital({ ...hospital, doctors });
  }

  const addDoctor = () => {
    const lastDoctor = hospital.doctors[hospital.doctors.length - 1];
  
    // Check if lastDoctor is defined before trying to access its properties
    if (lastDoctor && (lastDoctor.name === '' || lastDoctor.speciality === '')) {
      alert('Please fill out the details for the current doctor before adding a new one.');
    } else {
      setHospital({ ...hospital, doctors: [...hospital.doctors, { name: '', speciality: '' }] });
    }
  }


  const removeDoctor = (index) => {
    const doctors = [...hospital.doctors];
    doctors.splice(index, 1);
    setHospital({ ...hospital, doctors });
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check if all doctors have their name and speciality filled out
    for (let doctor of hospital.doctors) {
      if (doctor.name === '' || doctor.speciality === '') {
        alert('Please fill out the name and speciality for all doctors.');
        return;
      }
    }
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/registrationRequest`, hospital);
      //console.log(response.data);
      alert('Registration request received .We will get back to you at the earliest.');
      navigate('/');
    } catch (error) {
      if (error.response && error.response.status === 422) {
        console.error('Hospital number already exists.');
      } else {
        console.error(error);
      }
    }
  }
  return (
    <>
    <div className='register-header'>
    <h1>Register Your Hospital</h1>
    <h5>Fill And Submit The Form, We Will Notify You When You Are Successfully Registred </h5>
    </div>

    <form onSubmit={handleSubmit} className="hospital-form">
      <label className="form-label">
        Centre Name:
        <input type="text" name="name" value={hospital.name} onChange={handleHospitalChange} required className="form-input" />
      </label>
      <label className="form-label">
  Address:
  <textarea
    name="address"
    value={hospital.address}
    onChange={handleHospitalChange}
    required
    className="form-input"
    rows="3" // Optional: Set initial number of rows
    style={{ resize: 'both' }} // Allow both horizontal and vertical resizing
  />
</label>
      <label className="form-label">
        Pincode:
        <input type="text" name="pincode" value={hospital.pincode} onChange={handleHospitalChange} required className="form-input" />
      </label>
      <label className="form-label">
        Contact Number:
        <input type="text" name="contactNo" value={hospital.contactNo} onChange={handleHospitalChange} required className="form-input" />
      </label>
      <label className="form-label">
        Email:
        <input type="email" name="contactEmail" value={hospital.contactEmail} onChange={handleHospitalChange} required className="form-input" />
      </label>
      {hospital.doctors.map((doctor, index) => (
        <div key={index} className="doctor-info">
          <label className="form-label">
            Doctor Name:
            <input type="text" name="name" value={doctor.name} onChange={(e) => handleDoctorChange(e, index)} required className="form-input" />
          </label>
          <label className="form-label">
            Speciality:
            <input type="text" name="speciality" value={doctor.speciality} onChange={(e) => handleDoctorChange(e, index)} required className="form-input" />
          </label>
                <button type="button" onClick={() => removeDoctor(index)} className="remove-doctor-button">X</button>
        </div>
      ))}
      <button type="button" onClick={addDoctor} className="add-doctor-button">Add Doctor</button>

      <button type="submit" className="submit-button">Submit</button>
    </form>
    </>
  );
}

export default HospitalForm;