import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./HospitalListForUsers.css";
import UserHeader from "./UserHeader";
import AppointmentBookingForm from "./AppointmentBookingForm";

function HospitalListForUsers() {
  const [showSlots, setShowSlots] = useState(false);
  const [hospitals, setHospitals] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [showLogoutMessage, setShowLogoutMessage] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [loggedInUser, setLoggedInUser] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [displayCount, setDisplayCount] = useState(5);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [expandedDoctor, setExpandedDoctor] = useState(null); // New state variable
  const navigate = useNavigate();
  const [patientDetails, setPatientDetails] = useState({
    name: "",
    age: "",
    gender: "Male",
  });

  const selectSlot = (slot) => {
    setSelectedSlot(slot);
  };

  useEffect(() => {
    fetchHospitals();
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/auth/userprofile`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLoggedInUser(response.data);
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setShowLogoutMessage(true);
        } else {
          console.error("An error occurred while fetching the user profile:", error);
        }
      }
    };
    fetchUserProfile();
  }, []);




  const handleLoginRedirect = () => {
    window.location.href = '/userlogin';
  };

  const selectDoctor = (doctor) => {
    if (selectedDoctor === doctor) {
      setSelectedDoctor(null);
      setShowForm(false);
    } else {
      if (doctor.availability === "Not Available") {
        alert(`${doctor.name} is not available.`);
      } else {
        setSelectedDoctor(doctor);
        setShowForm(true);
      }
    }
  };

  const fetchHospitals = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/GetAllHospitals`
      );
      setHospitals(response.data);
    } catch (error) {
      console.error("Failed to fetch hospitals", error);
    }
  };

  const toggleDoctors = (hospitalId) => {
    if (selectedHospital === hospitalId) {
      setSelectedHospital(null);
    } else {
      setSelectedHospital(hospitalId);
    }
  };

  const handleInputChange = (event) => {
    setPatientDetails({
      ...patientDetails,
      [event.target.name]: event.target.value,
    });
  };

  const bookAppointment = async (doctor, hospital) => {
    setSelectedDoctor(doctor);
    setShowForm(true);
    const appointmentDetails = {
      doctorId: doctor._id,
      hospitalId: hospital._id,
      patient: patientDetails,
      user: loggedInUser,
      slot: selectedSlot,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/BookAppointment`,
        appointmentDetails
      );
      alert(
        "Appointment for " +
          response.data.patient.name +
          " is booked successfully and the assigned token no is " +
          response.data.queueNumber
      );
      navigate("/usershomepage");
    } catch (error) {
      console.error("Failed to book appointment", error);
      if (error.response) {
        if (error.response.status === 404) {
          alert(error.response.data.message);
        } else if (error.response.status === 410) {
          alert(error.response.data.message);
        } else {
          alert("Failed to book appointment. Please try again.");
        }
      } else {
        alert("Failed to book appointment. Please try again.");
      }
      window.location.reload();
    }
  };

  const filteredHospitals = Array.isArray(hospitals) ? hospitals.filter(
    (hospital) =>
      hospital.pincode.includes(searchTerm) ||
      hospital.name.toLowerCase().includes(searchTerm.toLowerCase())
  ) : [];

  const handleViewMore = () => {
    setDisplayCount(Array.isArray(hospitals) ? hospitals.length : 0);
  };

  const toggleSlots = (doctorId) => {
    if (expandedDoctor === doctorId) {
      setExpandedDoctor(null);
    } else {
      setExpandedDoctor(doctorId);
    }
  };

  return (
    <>
      {showLogoutMessage ? (
        <div className="logout-message">
          <p>You have been logged out due to inactivity.</p>
          <button onClick={handleLoginRedirect}>Login</button>
        </div>
      ) : (
        <>
          <UserHeader />
          <div className="user-hospitallist">
            <div className="hospital-list">
              <h3>Search for hospitals, clinics and diagnostic centers</h3>
              <input
                type="text"
                placeholder="Search by pincode or centre name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {filteredHospitals.slice(0, displayCount).map((hospital) => (
                <div key={hospital._id} className="user-hospital-box">
                  <p
                    onClick={() => toggleDoctors(hospital._id)}
                    className="hospital-name"
                  >
                    {hospital.name}
                  </p>
                  <div className="address-container">
                    <span className="address-label">Address: </span>
                    <span className="address-details">
                      {hospital.address}
                      <br />
                      Pincode: {hospital.pincode}
                    </span>
                  </div>
                  <div className="hospital-doctors">
                    {selectedHospital === hospital._id &&
                      hospital.doctors.map((doctor) => (
                        <div key={doctor._id} className="doctor-info">
                          <div className="doctor-info-p" onClick={() => toggleSlots(doctor._id)}>
                            <p className="doctor-info-dname">
                              {doctor.name} ({doctor.speciality})
                            </p>
                            <p
  className="doctor-info-p-availability"
  style={{ color: doctor.availability === 'Available' ? '#14d77c' : 'red',
    border: doctor.availability === 'Available' ? '1px solid #14d77c' : '1px solid red',
   }}
>
  {doctor.availability}
</p>
                            {doctor.timings && (
                              <p className="doctor-info-p-timings">
                                {doctor.timings.start} - {doctor.timings.end}
                              </p>
                            )}
                          </div>
                          {expandedDoctor === doctor._id && (
                            <div className="slots-buttons">
                              {doctor.slots.map((slot) => (
                                <button
                                  key={slot._id}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    selectSlot(slot);
                                    selectDoctor(doctor);
                                    setShowForm(true);
                                  }}
                                >
                                  {slot && `${slot.name} ${slot.startTime} to ${slot.endTime}`}
                                </button>
                              ))}
                            </div>
                          )}
                              <div className="modal-overlay" onClick={() => setShowForm(false)}></div>
                          {showForm && selectedDoctor === doctor && selectedSlot && selectedHospital &&
                            (selectedSlot.active ? (
                              <div className="modal">
                                <AppointmentBookingForm
                                  slot={selectedSlot}
                                  doctor={selectedDoctor}
                                  hospitalId={hospital}
                                  handleInputChange={handleInputChange}
                                  bookAppointment={bookAppointment}
                                  patientDetails={patientDetails}
                                  setShowForm={setShowForm}
                                />
                              </div>
                            ) : (
                              <p className="slot-alert">{selectedSlot.name} is full</p>
                            ))}
                        </div>
                      ))}
                  </div>
                </div>
              ))}
              {displayCount < hospitals.length && (
                <button className="view-more" onClick={handleViewMore}>View More</button>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default HospitalListForUsers;