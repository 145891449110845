import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './HospitalLogin.css';

function HospitalLogin() {
  const [hospitalNumber, setHospitalNumber] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();


  const onSubmit = async (e) => {
    e.preventDefault();

    const hospital = {
         hospitalNumber,
          password
         };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/hospitallogin`, hospital);
      localStorage.setItem('hospitaltoken', response.data.hospitaltoken);
      //console.log(response.data);
      //console.log(response.data.hospitaltoken);

//HospitalLogin(response.data.hospital);
      alert('Hospital logged in successfully');

  // Redirect to new route
  navigate('/hospital-dashboard');
} catch (error) {
  if (error.response && error.response.status === 401) {
    alert('Incorrect password');
  } else if (error.response && error.response.status === 400) {
    alert('Hospital No is not valid');
  }
  else {
    alert('Error logging in');
  }
}
};

  return (
  
    <div className="android-large-7">
      <div className="android-large-7-child" />

      <b className="health-eagle-2">
        <span className="health-eagle-txt-container">
          <span>Q24 HEALTH</span>
          <span className="span">{` `}</span>
        </span>
      </b>
      <h1 className='Login-for-doctors'>LOGIN</h1>
      <b className="for-medical-staff">For Medical staff</b>
      <div className="where-care-meets-container">
        <span className="health-eagle-txt-container">
          <p className="where-care-meets">{`Where Care Meets Convenience. `}</p>
          <p className="log-in-to">Log in to enhance patient experiences</p>
        </span>
      </div>

      
    <form onSubmit={onSubmit}>
    
        <input className="enter-hospital-code" type="text" placeholder="Hospital Number" value={hospitalNumber} onChange={(e) => setHospitalNumber(e.target.value)} required />


        <input className="password" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />

    <button className="hospilogin" type="submit">LOGIN</button>

    </form>



    </div>

    
  );
};


export default HospitalLogin;