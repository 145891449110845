import React from "react";
import { NavLink , useLocation } from 'react-router-dom'
import './HospitalHeader.css'

const HospitalHeader = () => {
    const location = useLocation();

    return (
        <>
        <div className='hospitalheader'>
            <div className='hospitalheader-container'>
            <a href="/" style={{ textDecoration: 'none', color: 'inherit' }}>
  <h1>Q24 HEALTH</h1>
</a>
            <h6>for Hospital staff</h6>
            <div className="hospital-nav-menu">
                <ul className='hospital-nav-menu-list'>
                <NavLink to='/load-hospital-details' style={{color: location.pathname === '/load-hospital-details' ? '#14d77c' : '#000000'} }><li>Settings</li></NavLink>
                <NavLink to='/hospital-dashboard' style={{color: location.pathname === '/hospital-dashboard' ? '#14d77c' : '#000000'} }><li>Dashboard</li></NavLink>
                </ul>
             </div>
             </div>
         </div>
            <div className="hospitalheader-mobilecontainer">

                <NavLink to='/load-hospital-details'>
                    <div style={{
                        backgroundColor: location.pathname === '/load-hospital-details' ? '#14d77c' : '#ffffff',
                        color: location.pathname === '/load-hospital-details' ? '#ffffff' : '#000000',
                        fontSize: '16px' , fontWeight: 'bold'
                    }} className="hospitalheader-mobile-home">
                        Settings
                    </div>
                </NavLink>
                <NavLink to="/hospital-dashboard">
  <div style={{
    backgroundColor: location.pathname === '/hospital-dashboard' ? '#14d77c' : '#ffffff',
    color: location.pathname === '/hospital-dashboard' ? '#ffffff' : '#000000',
    fontSize: '16px' , fontWeight: 'bold'
  }} className="hospitalheader-mobile-dashboard">
    Dashboard
  </div>
</NavLink>
            </div>

        </>
    )
}

export default HospitalHeader
