import React from 'react';
import AdminNavbar from './AdminNavbar';

function Admin() {
  return (
    <div>
      <h1>This is admin page </h1>
      <AdminNavbar/>
    </div>
  );
}

export default Admin;