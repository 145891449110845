import React, { useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client';
import axios from 'axios';
import './ActiveAppointments.css';
import UserHeader from './UserHeader';

const ENDPOINT = `${process.env.REACT_APP_API_BASE_URL}`;

function ActiveAppointments() {
  const [appointments, setAppointments] = useState([]);
  const [currentQueueNumbers, setCurrentQueueNumbers] = useState(
    JSON.parse(localStorage.getItem('currentQueueNumbers')) || {}
  );

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.on("queue update", data => {
      setCurrentQueueNumbers(prevNumbers => {
        const updatedNumbers = {
          ...prevNumbers,
          [data.slotId]: data.currentQueueNumber
        };
        localStorage.setItem('currentQueueNumbers', JSON.stringify(updatedNumbers));
        return updatedNumbers;
      });
      appointments.forEach(appointment => {
        if (appointment.slotId === data.slotId && data.currentQueueNumber === appointment.queueNumber - 3) {
          if (!("Notification" in window)) {
            //console.log("This browser does not support desktop notification");
          } else if (Notification.permission === "granted") {
            new Notification("It's time to go to the clinic");
          } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(function (permission) {
              if (permission === "granted") {
                new Notification("It's time to go to the clinic");
              }
            });
          }
        }
      });
    });
    return () => socket.disconnect();
  }, [appointments]);

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/auth/userprofile`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const response1 = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/appointments?usermobileNo=${response.data.mobileNo}`);
      setAppointments(response1.data);
    } catch (error) {
      console.error('Failed to fetch user', error);
    }
  };

  const cancelAppointment = async (id) => {
    if (window.confirm('Are you sure you want to cancel this appointment?')) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/appointments/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        alert(response.data.message);
        fetchAppointments();
      } catch (error) {
        console.error('Failed to cancel appointment', error);
      }
    }
  };

  return (
    <div className="appointment-container">
      <UserHeader />
      
      {Array.isArray(appointments) && appointments.length === 0 ? (
        <p>You have no active appointments</p>
      ) : (
        Array.isArray(appointments) ? appointments.map((appointment) => {
          const doctor = appointment.hospitalId 
            ? appointment.hospitalId.doctors.find(doctor => doctor._id === appointment.doctorId)
            : null;
          return (
            
            <div key={appointment._id} className="appointment-box">
              <div className='appnt-dic'><h3>Patient:</h3> <p> {appointment.patient.name}</p></div>
              <div className='appnt-dic'><h3>Doctor:</h3> <p> {doctor ? doctor.name : 'Unknown'}</p></div>
            <div className='appnt-dic'><h3>Hospital:</h3> <p> {appointment.hospitalId ? appointment.hospitalId.name : 'Unknown'}</p></div>
             <div className='appnt-dic'><h3>Hospital code:</h3> <p> {appointment.hospitalId ? appointment.hospitalId.hospitalNumber : 'Unknown'}</p></div>
             <div className='appnt-dic'><h3>Date:</h3> <p> {new Date(appointment.appointmentDate).toLocaleDateString()}</p></div>
             <div className='appnt-dic'><h3>Slot:</h3> <p> {appointment.slot.startTime} - {appointment.slot.endTime}</p></div>
            <div className='appnt-dic'><h3 className="current-token-number">Current Token number:</h3>  <p className={currentQueueNumbers[appointment.doctorId] ? 'blink' : ''}>
            {currentQueueNumbers[appointment.doctorId]}
          </p></div> 
             <div className='appnt-dic'><h3>Assigned Token number:</h3>  <p className='plink'>{appointment.queueNumber}</p></div>
              <button onClick={() => cancelAppointment(appointment._id)} className="cancel-button">Cancel Appointment</button>
            </div>
          );
        }) : <p>Error: Appointments data is not available.</p>
      )}
    </div>
  );
}

export default ActiveAppointments;