import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import './HospitalList.css';

function HospitalList() {
    const [hospitals, setHospitals] = useState([]);
    const [selectedHospital, setSelectedHospital] = useState(null);
    const [searchTerm, setSearchTerm] = useState(''); 
    const [displayCount, setDisplayCount] = useState(5); // to show only 5 hosptials initially
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        fetchHospitals();
    }, []);

    const fetchHospitals = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/GetAllHospitals`);
            setHospitals(response.data);
            //console.log(response.data);
        } catch (error) {
            console.error('Failed to fetch hospitals', error);
        }
    };

    const toggleDoctors = (hospitalId) => {
        if (selectedHospital === hospitalId) {
            setSelectedHospital(null);
        } else {
            setSelectedHospital(hospitalId);
        }
    };

    const handleDoctorClick = () => {
        alert('Please log in first');
        navigate('/userlogin');
    };
    const filteredHospitals = Array.isArray(hospitals) ? hospitals.filter(hospital => 
        hospital.pincode.includes(searchTerm) || hospital.name.toLowerCase().includes(searchTerm.toLowerCase())
    ) : [];


    const handleViewMore = () => {
        setDisplayCount(hospitals.length); // Update the display count to total number of hospitals
    };
  

    return (
        <div className="hospital-list" >
                <div className="appointment-message">Now you can easily book and track your offline appointments at nearby clinics and diagnostic centers.</div>
            <input 
                type="text" 
                placeholder="Search by pincode or centre name" 
                value={searchTerm} 
                onChange={e => setSearchTerm(e.target.value)} 
            />

            {filteredHospitals.slice(0, displayCount).map((hospital) => (
                <div className="hospital-box" onClick={() => toggleDoctors(hospital._id)} key={hospital._id} >
                <p  className='hospital-name'>{hospital.name}</p>
                <div className="address-container">
                    <span>{hospital.doctors.availability}</span>
  <span className="address-label">Address: </span>
  <span className="address-details">{hospital.address}<br/>Pincode: {hospital.pincode}</span>
</div>
{selectedHospital === hospital._id && hospital.doctors.map((doctor) => {
  return (
    <div key={doctor._id} className="doctor-info">
      <div className="doctor-info-p">
        <p className="doctor-info-dname" onClick={handleDoctorClick}>{doctor.name} ({doctor.speciality}) </p>
        <p
  className="doctor-info-p-availability"
  style={{
    color: doctor.availability === 'Available' ? '#14d77c' : 'red',
    border: `1px solid ${doctor.availability === 'Available' ? '#14d77c' : 'red'}` // Optional: Add border-radius for rounded corners
  }}
>
  {doctor.availability}
</p>
        {doctor.timings && <p className="doctor-info-p-timings">{doctor.timings.start} - {doctor.timings.end}</p>}
      </div>
    </div>
  );
})}
              </div>
            ))}

            {displayCount < hospitals.length && ( // Only display the "View More" button if there are more hospitals to display
                <button className='viewmore-button' onClick={handleViewMore}>View More</button>
            )}
        </div>
    );
}

export default HospitalList;