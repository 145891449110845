import React, { useState, useEffect } from 'react';
import Header from './Header';
import './Home.css';
import HospitalList from './HospitalList';
import illustration1 from './Assets/illustration1.png';
import { Link } from 'react-router-dom';

const Home = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallMessage, setShowInstallMessage] = useState(false);
  const [activeSection, setActiveSection] = useState('doctors');

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallMessage(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
      setShowInstallMessage(false);
    }
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setShowInstallMessage(false);
      });
    }
  };

  const handleCloseClick = () => {
    setShowInstallMessage(false);
  };

  return (
    <>
    <div className='main' >
    <Header />
   
    <div className='main-container'>

      <div className='curation'>

      <div className="welcome-message">
        <h1 className="welcome-to">WELCOME TO</h1><br/>
        <h1 className="health-eagle"> Q24 HEALTH!!</h1>
      </div>


      <div className="healthcare-message">WHERE HEALTHCARE MEETS CONVENIENCE</div>
      <div className='browser-compatibility-message'>Recommended to use Chrome and Edge browsers .</div>
     
      {/* {showInstallMessage && (
        
      )} */}


          <div className='install-msg-btn'>   <button  onClick={handleInstallClick}>Click here to Install App</button></div>


      {/* <div className="care-guide-message">
       <p> “LET US GUIDE YOU TO THE CARE YOU DESERVE,</p>
       <p> RIGHT IN YOUR NEIGHBORHOOD!!!"</p>
      </div> */}





    </div>

  
    <div className='home-illustration'>
  
      <img src={illustration1} alt=''></img>
    </div>
    </div>
        <div className="medical-staff-container">
    <h2>Medical Staff</h2>
      <p>Are you a medical staff member? Login or register here.</p>
    <div className='banner-medicalstaff' id='Home'>
      
  <Link to="/receptionlogin" className="button-link">Login As Medical Staff</Link>
  <Link to="/registration-form" className="button-link">Register as Medical Staff</Link>
</div>
</div>


    <div className='Hospitals-container' id='Hospitals'>

    <HospitalList />

    </div>

    <div className="services-section" id="Services">
    <h2 style={{ textAlign: 'center' }}>Our Services</h2>
      <div className="tabs">
        <button
          className={activeSection === 'doctors' ? 'active' : ''}
          onClick={() => setActiveSection('doctors')}
        >
          Doctors
        </button>
        <button
          className={activeSection === 'users' ? 'active' : ''}
          onClick={() => setActiveSection('users')}
        >
          Patient
        </button>
      </div>
      <div className="services-list">
        {activeSection === 'doctors' && (
          <div className="service-item">
            <h3>Features</h3>
            <ul>
  <li><strong>Manage Appointments:</strong> Easily view, schedule, and update patient appointments.</li><br/>
  <li><strong>Access Patient Records:</strong> Quickly review and update patient medical histories.</li><br/>
  <li><strong>Token Management:</strong> Issue and track tokens for patient visits.</li><br/>
  <li><strong>User-Friendly Design:</strong> Simple interface accessible on both desktop and mobile.</li><br/>
</ul>
          </div>
        )}
        {activeSection === 'users' && (
          <div className="service-item">
            <h3>Features</h3>
            <ul>
              
      <li><strong>Search for Healthcare Providers:</strong> Find hospitals and clinics in your nearby areas.</li><br/>
      <li><strong>Book Appointments:</strong> Book appointments and track your token number using your smartphone.</li><br/>
      <li><strong>Token Tracking:</strong> Save your time and energy spent waiting at hospitals.</li><br/>
      <li><strong>User-Friendly Interface:</strong> The application is easy to use.</li><br/>
    </ul>
          </div>
        )}
      </div>
    </div>

      <div className="contact-section">
        <h2>Contact Us</h2>
        <p>Phone: +91-9019945916</p>
        <p>Phone: +91-6363808973</p>
        <p>Email: contactq24@gmail.com</p>
        <p>Website: <a href="https://www.q24.xyz" target="_blank" rel="noopener noreferrer">www.q24.xyz</a></p>
      </div>

      <footer className="footer-section">
        <div className="footer-content">
          <p>&copy; 2024 Q24 Health App. All rights reserved.</p>
          <div className="footer-links">
            <Link to="/terms-of-service">Terms of Service</Link>
            
          </div>
        </div>
      </footer>



    </div>
    </>
  );
};

export default Home;





//code for round toggle
/* <div className='circle'>
<div className='inner-div'>
  <div></div>
  <div></div>
  <div></div>
</div>
</div> */