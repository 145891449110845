// import { useNavigate } from 'react-router-dom'; 
// import HospitalListForUsers from './HospitalListForUsers';
// import axios from 'axios';
import React from 'react';
import './UsersHomepage.css';
import FirstAppointment from './FirstAppointment';
import UserHeader from './UserHeader';
import { useNavigate } from 'react-router-dom';





function UsersHomepage() {
    const navigate = useNavigate();

// const[userName,setUserName]=useState('');
// useEffect(() => {
//     const fetchUser = async () => {
//       try {
//         const token = localStorage.getItem('token');
//         const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/auth/userprofile`, {
//           headers: {
//             'Authorization': `Bearer ${token}`
//           }
//         });
//         setUserName(response.data.fullName);
//       } catch (error) {
//         console.error('Failed to fetch user', error);
//       }
//     };

//     fetchUser();
//   }, []);

    // const handleLogout = () => {
    //     localStorage.clear(); // clear local storage
    //     navigate('/'); // redirect to landing page
    //   };

      // const handleProfilepageRedirect = () => {
      //   navigate('/userprofilepage');
      // };
      
    const handleLogout = () => {
      if (window.confirm('Are you sure you want to logout?')) {
        localStorage.clear(); // clear local storage
        navigate('/'); // redirect to landing page
      }
    };
      

  return (
    


    <div className='greet-user'>
     <UserHeader/>
     <div className="logout-button" onClick={handleLogout}>LOGOUT</div>

      <FirstAppointment/>
      {/* <HospitalListForUsers/> */}
      {/* <button onClick={handleProfilepageRedirect}>My Profile</button> */}


      
      {/* <button onClick={handleLogout}>Logout</button> */}


      {/* <ViewAppointmentsForUsers/> */}
    </div>
  );
};

export default UsersHomepage;