import { useState } from 'react';
import React from 'react';
import Admin from './Admin'; // Import the Admin component as a default export


export function AdminWithPasswordPrompt() {
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);

  const handlePasswordSubmit = (event) => {
    event.preventDefault();
    if (password === 'elonmusk') {
      setAuthenticated(true);
    } else {
      alert('Incorrect password');
    }
  };

  if (authenticated) {
    return <Admin />;
  } else {
    return (
      <form onSubmit={handlePasswordSubmit}>
        <label>
          Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        <input type="submit" value="Submit" />
      </form>
    );
  }
}

